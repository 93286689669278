import React from "react";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FVEData } from "./Interface/fveData";
import { GET_FVE_DATA } from "./graphql/fveData";
import { batteryState } from "./globalFunction/global";
import { useTranslation } from "react-i18next";

const Params = () => {
  const { t } = useTranslation();

  const { data } = useQuery<FVEData>(GET_FVE_DATA, {
    pollInterval: 5000,
    fetchPolicy: "network-only",
  });
  let total: number = 0;
  if (data) {
    total =
      data?.data_fve[0]?.battery_current * data?.data_fve[0]?.battery_voltage;
  }
  return (
    <>
      <main>
        <Box sx={{ background: "#272727", color: "#fff", paddingBottom: 10 }}>
          <Grid container>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("load")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].load} W
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: 1 }}></Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv_total")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv_total} W
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv1_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv1_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv1_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv1_current}
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv1_power")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv1_power.toFixed(2)} W
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv2_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv2_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv2_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv2_current} A
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("pv2_power")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv2_power.toFixed(2)} W
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: 1 }}></Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("grid_reactive_power")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].grid_reactive_power} var
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("grid_active_power")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].grid_active_power} W
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("grid_apparent_power")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].grid_apparent_power} VA
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: 1 }}></Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_SOC")}
            </Grid>

            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_SOC} %
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_SOH")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_SOH} %
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_current} A
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_current_total")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {total.toFixed(2)} W
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_temperature")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_temperature} °C
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_state")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {batteryState(data?.data_fve[0]?.battery_state)}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: 1 }}></Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("a_phase_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.a_phase_current} A
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("a_phase_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.a_phase_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("ab_line_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.ab_line_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("b_phase_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.b_phase_current} A
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("b_phase_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.b_phase_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("bc_line_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.bc_line_voltage} V
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              {t("c_phase_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.c_phase_current} A
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("c_phase_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.c_phase_voltage} V
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("ca_line_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.ca_line_voltage} V
            </Grid>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default Params;
