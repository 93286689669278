import React, { useEffect, useState } from "react";
import "./App.css";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FVEData } from "./Interface/fveData";
import { GET_FVE_DATA } from "./graphql/fveData";
import { ResponsiveWaffle } from "@nivo/waffle";
import { batteryState } from "./globalFunction/global";
import { useTranslation } from "react-i18next";

const Battery = () => {
  const { t } = useTranslation();
  const { data } = useQuery<FVEData>(GET_FVE_DATA, {
    pollInterval: 5000,
    fetchPolicy: "network-only",
  });
  const [battery, setBattery] = useState(0);
  useEffect(() => {
    if (data) {
      setBattery(data.data_fve[0].battery_SOC);
    }
  }, [data]);

  let total: number = 0;
  let diff: number = 0;
  if (data) {
    total =
      data?.data_fve[0]?.battery_current * data?.data_fve[0]?.battery_voltage;
    diff = data?.data_fve[0].battery_SOH - data?.data_fve[0].battery_SOC;
  }
  console.log(battery)
  const data2 = [
    {
      id: "energy_left",
      label: "Energy left",
      value: battery,
      color: "#1b3558",
    },
    {
      id: "out_of_battery",
      label: "Out of battery",
      value: diff,
      color: "#1b3558",
    },

  ];
  return (
    <>
      <main>
        <div style={{ height: "6rem" }}>
          <ResponsiveWaffle
            data={data2}
            colors={["green", "#272727"]}
            total={100}
            rows={1}
            emptyOpacity={0.3}
            padding={8}
            theme={{
              tooltip: {
                container: {
                  color: "#000",
                },
              },
            }}
            columns={10}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            animate={true}
            motionStiffness={90}
            // motionConfig={11}
          />
        </div>
        <Box sx={{ background: "#272727", color: "#fff" }}>
          <Grid container>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_SOC")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_SOC} %
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_SOH")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_SOH} %
            </Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_voltage")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_voltage} V
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_current")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_current} A
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_current_total")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {total.toFixed(2)} W
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_temperature")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0]?.battery_temperature} °C
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              {t("battery_state")}
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {batteryState(data?.data_fve[0]?.battery_state)}
            </Grid>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default Battery;
