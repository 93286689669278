import i18n from "i18next";
import cs from "./cs/cs.json";
import en from "./en/en.json";
import { initReactI18next } from "react-i18next";
export const defaultNS = "cs";

export const resources = {
  cs: {
    cs,
  },
  en: en,
} as const;

i18n.use(initReactI18next).init({
  lng: "cs",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  ns: ["cs", "en"],
  defaultNS,
  resources,
});
