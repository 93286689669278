import React, { FC, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { FVEAvgFunction } from "./Interface/fveData";
import { GET_PV_SUM_DATA_HOUR } from "./graphql/fveData";
import dayjs, { Dayjs } from "dayjs";
import { AxisOptions, Chart } from "react-charts";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
  Box,
  CircularProgress,
  createTheme,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ThemeProvider,
} from "@mui/material";
import {
  DatePicker,
  DateRange,
  DateRangePicker,
  LicenseInfo,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import "dayjs/locale/cs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

LicenseInfo.setLicenseKey(
  "88117b5fee1756d52471ef77b07e4e89Tz02NTc5OSxFPTE3MTQ4MzUyNTM3MDEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);
interface DataPoint {
  primary: string;
  secondary: number;
}
dayjs.extend(utc);
dayjs.extend(timezone);

// interface AggregatedData {
//   [key: string]: number[]; // Indexová signatura
// }

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function transformFVEData(
  data: FVEAvgFunction,
  aggregation: "hour" | "day" | "month" | "year" = "hour",
) {
  let dataSource: any[] | undefined;
  let preparedData;

  const getDate = (value: any, type: any) => {
    switch (type) {
      case "hour":
        return dayjs(
          `${value.year}-${value.month.toString().padStart(2, "0")}-${value.day.toString().padStart(2, "0")} ${value.hour.toString().padStart(2, "0")}:00:00`,
        ).format("YYYY-MM-DD HH:mm:ss");

      case "day":
        return dayjs(
          `${value.year}-${value.month.toString().padStart(2, "0")}-${value.day.toString().padStart(2, "0")} 00:00:00`,
        ).format("YYYY-MM-DD");
    }
  };

  switch (aggregation) {
    case "hour":
      dataSource = data.function_sum_hour;
      preparedData =
        dataSource?.map((item: any, index: number) => {
          return {
            primary: getDate(item, aggregation),
            secondary:
              index === 0 && item?.pv_total > 0
                ? dataSource?.[index + 1]?.load
                : item.load,
            pv_total:
              index === 0 && item.pv_total > 0
                ? dataSource?.[index + 1]?.pv_total
                : item.pv_total,
          };
        }) ?? [];
      break;
    case "day":
      dataSource = data.function_sum_day;
      preparedData =
        dataSource?.map((item: any) => {
          return {
            primary: getDate(item, aggregation),
            secondary: item.load,
            pv_total: item.pv_total,
          };
        }) ?? [];
      break;
    case "month":
      dataSource = data.function_sum_month;
      preparedData =
        dataSource?.map((item: any) => {
          return {
            primary: dayjs(
              `${item.year}-${item.month.toString().padStart(2, "0")}-01 00:00:00`,
            ).format("YYYY-MM-DD"),
            secondary: item.sum_max_load,
            pv_total: item.sum_max_pv_total,
          };
        }) ?? [];
      break;
    case "year":
      dataSource = data.function_sum_year;
      preparedData =
        dataSource?.map((item: any) => {
          return {
            primary: item.year,
            secondary: item.sum_max_load,
            pv_total: item.sum_max_pv_total,
          };
        }) ?? [];
      break;
  }

  const loadSeries = {
    id: "load",
    label: "Spotřeba [kW]",
    data:
      preparedData.map(({ primary, secondary }) => ({
        primary,
        secondary,
      })) ?? [],
  };

  const pvTotalSeries = {
    id: "pv_total",
    label: "Výroba z panelů [kW]",
    data:
      preparedData.map(({ primary, pv_total }) => ({
        primary,
        secondary: pv_total,
      })) ?? [],
  };

  return [loadSeries, pvTotalSeries];
}

enum AggregationEnum {
  "hour" = "hour",
  "day" = "day",
  "month" = "month",
  "year" = "year",
}

const Graphs = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [aggregation, setAggregation] = useState<AggregationEnum>(
    AggregationEnum.hour,
  );
  const handleChange = (value: Dayjs | null) => {
    setStartDate(dayjs.utc(value as Dayjs).startOf("day"));
    setEndDate(value as Dayjs);
  };

  const handleChangeRange = (value: DateRange<dayjs.Dayjs | undefined>) => {
    setStartDate(dayjs.utc(value[0] as Dayjs).startOf("day"));
    setEndDate(dayjs.utc(value[1] as Dayjs).startOf("day"));
  };
  const handleAggregation = (e: SelectChangeEvent<AggregationEnum>) => {
    setAggregation(e.target.value as AggregationEnum);
  };

  useEffect(() => {
    setStartDate(dayjs().utc().startOf("day"));
    setEndDate(dayjs().utc().startOf("day"));
  }, []);

  const isValid = dayjs.utc(startDate).isValid();
  const isValid2 = dayjs.utc(endDate).isValid();

  return (
    <Box
      sx={{
        height: "98%",
        "@media (max-width:400px)": {
          height: `calc(100vh - 177px)`,
        },
      }}
    >
      <Box sx={{ height: `calc(100% - 40px)` }}>
        <AutoSizer>
          {(size: Size) => {
            return isValid && isValid2 ? (
              <CustomChart
                startDate={startDate}
                endDate={endDate}
                size={size}
                aggregation={aggregation}
              />
            ) : (
              <></>
            );
          }}
        </AutoSizer>
      </Box>
      <Box sx={{ height: "40px" }}>
        <ThemeProvider theme={darkTheme}>
          <Stack
            gap={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ padding: 1 }}
          >
            <Select
              value={aggregation}
              onChange={handleAggregation}
              size={"small"}
            >
              <MenuItem value={AggregationEnum.hour}>Hodiny</MenuItem>
              <MenuItem value={AggregationEnum.day}>Dny</MenuItem>
              <MenuItem value={AggregationEnum.month}>Mesice</MenuItem>
              <MenuItem value={AggregationEnum.year}>Roky</MenuItem>
            </Select>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"cs"}
            >
              {aggregation === AggregationEnum.hour && (
                <DatePicker
                  value={startDate}
                  format={"DD/MM/YYYY"}
                  onChange={handleChange}
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
              {(aggregation === AggregationEnum.day ||
                aggregation === AggregationEnum.month) && (
                <DateRangePicker
                  value={[startDate, endDate]}
                  format={"DD/MM/YYYY"}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={handleChangeRange}
                />
              )}
            </LocalizationProvider>
          </Stack>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

interface CustomChartProps {
  size: Size;
  aggregation: AggregationEnum;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}
const CustomChart: FC<CustomChartProps> = ({
  size,
  aggregation,
  startDate,
  endDate,
}) => {
  const { data, loading } = useQuery<FVEAvgFunction>(GET_PV_SUM_DATA_HOUR, {
    variables: {
      startdate: dayjs.utc(startDate).toISOString(),
      enddate: dayjs
        .utc(aggregation === AggregationEnum.hour ? startDate : endDate)
        .add(-10, "seconds")
        .add(1, "day")
        .toISOString(),
    },
    pollInterval: 10000,
    fetchPolicy: "network-only",
  });

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary as any,
      scaleType: "band",
      formatters: {
        scale: (value) => {
          if (aggregation === AggregationEnum.hour)
            return dayjs(value).format("HH:mm");
          if (aggregation === AggregationEnum.day)
            return dayjs(value).format("DD/MM/YYYY");
          if (aggregation === AggregationEnum.month)
            return dayjs(value).format("MM/YYYY");
          if (aggregation === AggregationEnum.year) return value;
        },
      },
      //tickLabelRotationDeg: 45,
    }),
    [aggregation],
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (value) => value.secondary,
        elementType: aggregation === AggregationEnum.hour ? "line" : "bar",
      },
    ],
    [aggregation],
  );

  if (!data || loading) {
    return (
      <Box
        sx={{
          height: size?.height,
          width: size?.width,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color={"info"} sx={{ height: "100%" }} size={150} />
      </Box>
    );
  }

  const modifyData = transformFVEData(data, aggregation);
  if (modifyData?.[0]?.data?.length === 0) return <></>;
  return (
    <Box width={`${size?.width}px`} height={`${size?.height}px`}>
      <Chart
        color={"white"}
        options={{
          dark: true,
          data: modifyData as any[],
          primaryAxis,
          secondaryAxes: secondaryAxes,
        }}
      />
    </Box>
  );
};
export default Graphs;
