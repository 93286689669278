import { gql } from "@apollo/client";
export const GET_SUM_FVE_DATA = gql`
  query MyQuery {
    sum(limit: 1, order_by: { id: desc }) {
      id
      grid_reactive_power
      grid_active_power
      load
      pv_total
      created_at
    }
  }
`;

export const GET_PV_DATA = gql`
  query MyQuery($startdate: timestamptz!, $enddate: timestamptz!) {
    function_data_fve_avg(args: { enddate: $enddate, startdate: $startdate }) {
      month
      pv_total
      year
      load
      hour
      day
      id
    }
  }
`;

export const GET_PV_SUM_DATA_HOUR = gql`
  query MyQuery($startdate: timestamptz!, $enddate: timestamptz!) {
    function_sum_hour(
      args: { enddate: $enddate, startdate: $startdate }
      order_by: { hour: asc, month: asc, day: asc, year: asc }
    ) {
      day
      pv_total
      year
      month
      load
      hour
    }
    function_sum_day(
      args: { enddate: $enddate, startdate: $startdate }
      order_by: { day: asc }
    ) {
      day
      pv_total
      year
      month
      load
    }
    function_sum_month(
      args: { enddate: $enddate, startdate: $startdate }
      order_by: { month: asc, year: asc }
    ) {
      sum_max_pv_total
      sum_max_load
      year
      month
    }
    function_sum_year(order_by: { year: asc }) {
      sum_max_pv_total
      sum_max_load
      year
    }
  }
`;

export const GET_PV_SUM_DATA_DAYS = gql`
  query MyQuery($startdate: timestamptz!, $enddate: timestamptz!) {
    function_sum_day(
      args: { enddate: $enddate, startdate: $startdate }
      order_by: { day: asc }
    ) {
      day
      pv_total
      year
      month
      load
    }
  }
`;
export const GET_FVE_DATA = gql`
  query MyQuery {
    data_fve(limit: 1, order_by: { created_at: desc }) {
      id
      pv_total
      a_phase_current
      a_phase_voltage
      ab_line_voltage
      bc_line_voltage
      c_phase_current
      c_phase_voltage
      ca_line_voltage
      b_phase_current
      b_phase_voltage
      backup_a_phase_current
      backup_active_power
      backup_a_phase_voltage
      backup_apparent_power
      backup_b_phase_current
      backup_b_phase_voltage
      backup_c_phase_current
      backup_c_phase_voltage
      backup_frequency
      backup_reactive_power
      battery_SOC
      battery_SOH
      battery_current
      battery_max_charging_current
      battery_max_discharging_current
      battery_state
      battery_temperature
      battery_voltage

      created_at
      grid_active_power
      grid_frequency
      grid_reactive_power
      grid_apparent_power
      pv2_voltage
      pv2_power
      pv2_current
      pv1_voltage
      pv1_power
      pv1_current

      load
    }
    sum(limit: 1, order_by: { id: desc }) {
      id
      grid_reactive_power
      grid_active_power
      load
      pv_total
      created_at
    }
  }
`;
