import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.log(process.env.REACT_APP_HASURA_URL);
const client = new ApolloClient({
  uri: process.env.REACT_APP_HASURA_URL,
  headers: {
    "x-hasura-admin-secret": process.env
      .REACT_APP_HASURA_ADMIN_SECRET as string,
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
  cache: new InMemoryCache(),
});
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
