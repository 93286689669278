import React, { Fragment } from "react";
import MenuAppBar from "./AppBar";
import { Box } from "@mui/material";

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const Layout: React.FC<Props> = (props) => {
  return (
    <Fragment>
      {/*<div className="App-header">*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `calc(100vh - 2px)`,
          "@media (max-width:400px)": {
            height: "-webkit-fill-available",
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            "@media (max-width:400px)": {
              overflowY: "scroll",
            },
            overflowY: "auto",
            p: 0,
          }}
        >
          {props.children}
        </Box>
        <Box sx={{ height: "56px", bgcolor: "lightcoral", p: 0 }}>
          <MenuAppBar />
        </Box>
      </Box>
      {/*</div>*/}
    </Fragment>
  );
};

export default Layout;
