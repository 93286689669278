import React from "react";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FVEData } from "./Interface/fveData";
import { GET_FVE_DATA } from "./graphql/fveData";

const GridReact = () => {
  const { data } = useQuery<FVEData>(GET_FVE_DATA, {
    pollInterval: 5000,
    fetchPolicy: "network-only",
  });
  let total: number = 0;
  if (data) {
    total = data.data_fve[0].pv1_power + data.data_fve[0].pv2_power;
  }
  return (
    <>
      <main>
        <Box sx={{ background: "#272727", color: "#fff" }}>
          <Grid container>
            <Grid item xs={7} sx={{ p: 1 }}>
              pv_total
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {total} W
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              pv1_voltage
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv1_voltage} V
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              pv1_current
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv1_current}
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              pv1_power
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv1_power.toFixed(2)} W
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              pv2_voltage
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv2_voltage} V
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              pv2_current
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv2_current}
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              pv2_power
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].pv2_power.toFixed(2)} W
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              grid_reactive_power
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].grid_reactive_power}
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              grid_active_power
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].grid_active_power}
            </Grid>

            <Grid item xs={7} sx={{ p: 1 }}>
              grid_apparent_power
            </Grid>
            <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
              {data?.data_fve[0].grid_apparent_power}
            </Grid>
          </Grid>
        </Box>
      </main>
    </>
  );
};

export default GridReact;
