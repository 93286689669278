import { useTranslation } from "react-i18next";

export const batteryState = (state: number | undefined): string => {
  const { t } = useTranslation();

  if (state === 0) return t("batteryState.stand-by");
  if (state === 1) return t("batteryState.charging");
  if (state === -1) return t("batteryState.discharging");
  return t("batteryState.stand-by");
};
