import React from "react";
import "./i18n/config";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Battery from "./Battery";
import Params from "./Params";
import Graphs from "./Graphs";
import Grid from "./Grid";

function App() {
  return (
    <Layout>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/battery" element={<Battery />} />
        <Route path="/params" element={<Params />} />
        <Route path="/grid" element={<Grid />} />
        <Route path="/graphs" element={<Graphs />} />
      </Routes>
    </Layout>
  );
}

export default App;
