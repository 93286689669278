import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, createTheme, Grid, ThemeProvider } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_FVE_DATA } from "./graphql/fveData";
import { ResponsivePie } from "@nivo/pie";
import HomeIcon from "@mui/icons-material/Home";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { FVESumData } from "./Interface/fveData";
import { batteryState } from "./globalFunction/global";
interface PieData {
  id: string | undefined;
  label: string;
  value: number | undefined;
  color: string;
  icons: JSX.Element;
}

const Home = () => {
  const { t } = useTranslation();
  const { data } = useQuery<FVESumData>(GET_FVE_DATA, {
    fetchPolicy: "no-cache",
    pollInterval: 5000,
  });
  let total = 0;
  let grid_active_power = 0;
  let grid_active_power_text = "";
  if (data) {
    total = data.data_fve[0].pv1_power + data.data_fve[0].pv2_power;
    grid_active_power_text =
      data?.data_fve[0].grid_active_power < 0
        ? "grid_active_power_import_online"
        : "grid_active_power_export_online";
    grid_active_power =
      data?.data_fve[0].grid_active_power < 0
        ? data?.data_fve[0].grid_active_power * -1
        : data?.data_fve[0].grid_active_power;
  }
  const data2: PieData[] = [
    {
      id: "total",
      label: t("production"),
      value: total,
      color: "#1f76b4",
      icons: (
        <CalendarViewMonthIcon style={{ fontSize: 60, color: "#1f76b4" }} />
      ),
    },
    {
      id: "load",
      label: t("consumption"),
      value: data?.data_fve[0].load,
      color: "orange",
      icons: <HomeIcon style={{ fontSize: 60, color: "orange" }} />,
    },
    {
      id: "grid_active_power",
      label: t(grid_active_power_text),
      value: grid_active_power < 0 ? grid_active_power * -1 : grid_active_power,
      color: "green",
      icons: <ElectricBoltIcon style={{ fontSize: 60, color: "green" }} />,
    },
  ];
  const [pieIndex, setPieIndex] = useState<PieData>();
  const onMouseEnter = (index: any) => {
    setPieIndex(data2?.find((value: PieData) => value?.id === index.id));
  };
  useEffect(() => {
    setPieIndex(data2[1]);
  }, [data]);

  const theme = createTheme({
    typography: {
      fontFamily: "Roboto, Arial",
      allVariants: {
        color: "white",
      },
    },
  });

  const CenterText = () => (
    <div
      style={{
        width: "150px",
        height: "150px",
        left: window.innerWidth / 2 - 75,
        position: "absolute",
        verticalAlign: "bottom",
        top: window.innerHeight / 2 / 2 - 100,
        textAlign: "center",
        fontSize: "2.5rem",
      }}
    >
      <div>{pieIndex?.icons}</div>
      <div>{pieIndex?.value} W</div>
      <div style={{ fontSize: "1.4rem", paddingTop: "5px" }}>
        {pieIndex?.label}
      </div>
    </div>
  );

  return (
    <>
      <main>
        <div style={{ height: window.innerHeight / 2 }}>
          <CenterText />
          <ResponsivePie
            data={data2}
            onMouseEnter={onMouseEnter}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.82}
            padAngle={2}
            colors={{ scheme: "category10" }}
            cornerRadius={5}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            activeOuterRadiusOffset={4}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.5]],
            }}
            legends={[
              {
                onClick: onMouseEnter,
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#fff",
                    },
                  },
                ],
              },
            ]}
            theme={{
              tooltip: {
                container: {
                  color: "#000",
                },
              },
            }}
            tooltip={() => <div></div>}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
          />

          <ThemeProvider theme={theme}>
            <Box sx={{ background: "#272727", color: "#fff" }}>
              <Grid container>
                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("consumption")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {data?.sum[0].load?.toFixed(2)} kW
                </Grid>

                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("pv_total_sum")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {data?.sum[0].pv_total?.toFixed(2)} kW
                </Grid>

                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("grid_active_power_import")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {data?.sum[0].grid_active_power?.toFixed(2) || 0} kW
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ background: "#272727", color: "#fff" }}>
              <Grid container>
                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("load")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {data?.data_fve[0].load} W
                </Grid>
                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("battery_SOC")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {data?.data_fve[0].battery_SOC} %
                </Grid>
                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("battery_current")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {data?.data_fve[0].battery_current.toFixed(2)} A
                </Grid>
                <Grid item xs={7} sx={{ p: 1 }}>
                  {t("battery_state")}
                </Grid>
                <Grid item xs={5} sx={{ p: 1, textAlign: "right" }}>
                  {batteryState(data?.data_fve[0].battery_state)}
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </div>
      </main>
    </>
  );
};

export default Home;
