import * as React from "react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TableChartIcon from "@mui/icons-material/TableChart";
import { Battery4Bar } from "@mui/icons-material";
// interface Props {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window?: () => Window;
// }
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

export default function MenuAppBar() {
  //props: Props
  // const { window } = props;
  //const drawerWidth = 240;
  const navItems = [
    { url: "", name: "Status", icons: <DashboardIcon /> },
    { url: "graphs", name: "Energy", icons: <EqualizerIcon /> },
    { url: "params", name: "Params", icons: <TableChartIcon /> },
    { url: "battery", name: "Battery", icons: <Battery4Bar /> },
    // { url: "setup", name: "Setup", icons: <MenuIcon /> },
  ];
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue));
  };
  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  /*const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        FVE Moravice
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            component={Link}
            to={item.url}
            key={item.url}
            disablePadding
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText
                primary={
                  <Typography style={{ color: "#FFFFFF" }}>
                    {item.name}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );*/

  const BottomNav = () => {
    return (
      <Paper
        sx={{
          // position: "absolute",
          // bottom: 0,
          // left: 0,
          // right: 0,
          margin: 0,
          padding: 0,
          height: "55px",
        }}
        elevation={3}
      >
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          {navItems.map((item) => (
            <BottomNavigationAction
              component={Link}
              to={item.url}
              key={item.url}
              label={item.name}
              icon={item?.icons}
            />
          ))}
        </BottomNavigation>
      </Paper>
    );
  };

  /*const container =
    window !== undefined ? () => window().document.body : undefined;*/
  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        {/*
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: "none", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ display: { sm: "block" } }}>
              {navItems.map((item) => (
                <Button
                  component={Link}
                  to={item.url}
                  key={item.url}
                  sx={{ color: "#fff" }}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Toolbar />
        */}
        <BottomNav />
      </Box>
    </ThemeProvider>
  );
}
